import DP from '../assets/images/flower_1.JPEG'

export default function Jumbotron() {
    return(
<div
  className="p-12 text-center bg-black relative overflow-hidden bg-no-repeat bg-cover rounded-lg -mt-28 "
  style={{
    backgroundImage: `url(${DP})`,
    height: "800px"
  }}
>
  <div
    className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
    style={{backgroundColor: "rgba(0, 0, 0, 0.2)"}}
  >
    <div className="flex justify-center items-center h-full">
      <div className="text-white">
        <h2 className="font-semibold text-4xl mb-4">Jas La Doula</h2>
        <h4 className="font-semibold text-xl mb-6 mx-20">
        Welcome ! I’m so glad you’re here. I appreciate you being here and I hope you will find the support that  you need . Please, use the links above to navigate through my offerings and to contact me. Thank you! </h4>
        <a
          className="inline-block px-7 py-3 mb-1 border-2 border-gray-200 text-gray-200 font-medium text-sm leading-snug uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          href="#Services"
          role="button"
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          >Services</a
        >
      </div>
    </div>
  </div>
</div>
    )
}
