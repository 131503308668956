import React from "react";

export default function Nav() {
    return (
      <header className="bg-lturquoise  top-0 z-10">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center text-white">
          <a className="title-font font-medium text-white mb-4 md:mb-0">
            <a href="#about" className="ml-3 text-xl">
              Jas La Doula
            </a>
          </a>
          <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700	flex flex-wrap items-center text-base justify-center">
            <a href="#Aboutme" className="mr-5 hover:text-white">
              About
            </a>
            <a href="#services" className="mr-5 hover:text-white">
              Services
            </a>
            <a href="#packages" className="mr-5 hover:text-white">
              Packages
            </a>
          </nav>
          <a
            href="#contact"
            className="inline-flex items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0">
            Contact Us
          </a>
        </div>
      </header>
    );
  }