import logo from './assets/images/giradelsol.PNG';
import selfPortraitImage from './assets/images/jasportrait.JPG';
import flowers from './assets/images/flower_1.JPEG';
import './App.css';
import Nav from './components/Nav';
import HeroSection from './components/HeroSection';
import PricingSection from './components/PricingSection';
import AboutMe from './components/AboutMe';
import CTAContact from './components/CTAContact';
import NewNav from './components/NewNav';
import Services from './components/Services';
import Jumbotron from './components/Jumbotron';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
<main className="text-black-400 bg-white-900 body-font">
      <NewNav logo={ logo } />
<Jumbotron />
     
      <AboutMe selfPortrait={selfPortraitImage}/>
      <PricingSection />
      <Services />
      <CTAContact imagesrc={flowers} />
      <Contact />
      <Footer />
</main>
  );
}

export default App;
