import PropTypes from 'prop-types';

const Service = ({
    servicename = '', 
    servicedescription = '',
    servicelineitems = [],
    serviceimage = ''
}) => (
    <div className="w-full">
    

    <div className="md:w-100 md:px-4">
        <div className="p-10
               md:px-7
               xl:px-10
               rounded-[20px]
               bg-white
               shadow-md
               hover:shadow-lg
               mb-8
               md:grid md:grid-cols-4 bg-lturquoise">
   

    <div className='md:col-span-3 md:p-10 mb-auto mt-auto'>
    <h1 className="font-lturquoise-bg font-semibold ltext-xl text-dark mb-3">{servicename}</h1>
    <p className="">{servicedescription}</p>
    
    <br /><br />
    <p className="font-lturquoise-bg text-dark font-semibold">What does this look like?</p>
    {servicelineitems.map(servicelineitem => (
        <li>{servicelineitem}
            </li>
    )
    
        
    )}
    </div>
    <div className='md:col-span-1 mb-auto mt-auto'>
    <img src={serviceimage} width="100%" />
    </div>
        </div>
    
    </div>
</div>
);

Service.propTypes = {
    servicename: PropTypes.string,
    servicedescription: PropTypes.string,
    servicelineitem: PropTypes.arrayOf(PropTypes.string),
    serviceimage: PropTypes.string,
  };
  
export default Service;