import React from "react";
import Service from "./Service";
import PH from '../assets/images/books.jpeg';
import holdingBelly from '../assets/images/holding_waist.JPEG';
import holdingBaby from '../assets/images/holding_baby.JPEG';
import teas from '../assets/images/jars_herbal_teas.jpeg';
import Books from '../assets/images/books2.jpeg';
import reaching from '../assets/images/reaching_over_touching_flower.jpeg';
export const services = [
    {
        servicename: "Postpartum care planning",
        servicedescription: `* support during the transition of the mind body and soul*
        Assisting in making a postpartum plan, this first stage happens during pregnancy.`,
        servicelineitems: [
            "During the third trimester, we will be creating a postpartum plan specific to your needs. Making your transition with baby as  supported as possible. Whether it’s meals delivered , menu suggestions , culturally relevant and nutritious meal ideas/recipes, referrals for wellness etc. Placenta medicine and benefits will also be discussed."
        ],
        serviceimage: PH
    },
    {
        servicename: "Postpartum Visits",
        servicedescription: "I will meet with you virtually* or at your home.",
        servicelineitems: [
            "I will check-in with you and have a platica (healing talks).",
            "I will offer you lactation education, guidance, newborn care tips, and share resources with you.",
            "Assist with caring for baby to facilitate a nap or shower.",
            "Make an herbal postpartum tea and leave extra for you to drink after our meeting.",
            "I will answer any questions you may have as well as address any concerns. If meeting virtually , I will deliver or send the herbal tea blend for you.",
        ],
        serviceimage: holdingBelly
    },
    {
        servicename: "Placenta Medicine",
        servicedescription: "I will go over the proper planning and procedures about your placenta pick up  with you during the pregnancy period.",
        servicelineitems: [
            "Once I pick up the placenta, I will encapsulate it in a safe and clean environment (I am OSHA certified to prepare placentas in a clean and safe manner).",
            "I will also be making the placenta tincture, along with an umbilical cord keepsake and placenta Art for you to keep."
        ],
        serviceimage: Books
    },
    {
        servicename: "Belly Binding",
        servicedescription: "*When giving birth, the birthing parent’s body goes through an opening process. This process involves opening of all the energetic centers in the body. The most affected areas are felt in the first, second, and third chakras. Belly binding provides protection and closure of these three chakras.*",
        servicelineitems: [
            "I will begin with an abdominal massage, followed by a bone hold, and closing with an elastic bandage to hold you and support you.",
            "2 home visits.",
            "I provide space to have healing talks as we sip an herbal tea blend made by me.",
            "Provide and abdominal massage and bone hold.",
            "Wrap you in an elastic bandage.",
            "Provide a warming organic massage oil made by me.",
            "Teach a family member to assist you in binding you."
        ],
        serviceimage: teas
    },
    {
        servicename: "Newborn Care Course",
        servicedescription: "What to expect in the first weeks of baby’s life, support, resources , and referrals if needed.",
        servicelineitems: [
            "One hour on-line course."
        ],
        serviceimage: holdingBaby
    },
    {
        servicename: "Birth Doula Support",
        servicedescription: "",
        servicelineitems: [
            "Meet with you virtually or in person to go over your birth plan.",
            "Support you with information and material surrounding birth.",
            "On call the week before and after your delivery due date.",
            "Support during your birth ceremony.",
            "One postpartum visit."
        ],
        serviceimage: reaching
    }
];

export default function Services() {
    return (
        <div className="relative px-4 items-center relative bg-nwhite" id="Services">
        <div className="w-full bg-nwhite text-center">
            <h1 className="text-[36px] font-lturquoise-bg pt-8 mb-8 font-bold w-full mx-auto">Services</h1>

        </div>
        {services.map(service => (
            <Service {...service} />
        ))}

        </div>
    )
}