


export default function AboutMe({selfPortrait}) {
    return (
        /*
        <div className="min-h-full relative bg-white overflow-hidden bg-center
        opacity-80 bg-cover bg-local bg-blend-multiply bg-[url('../public/letter-bg.jpg')]">
           
            <div className="w-full items-center p-30 grid mx-auto mt-10">
                <h2 className="text-4xl mx-auto cursive-letter">About Me</h2>
                <div >
                    <p className="mx-auto cursive-letter p-10 lg:w-3/4">
                    My curiosity has led me to many adventures, one being birth. When I became pregnant for the first time I naturally began research of what happens to the body and what the body is capable of during pregnancy, postpartum and beyond. My outlook on birthing and the postpartum period has evolved throughout the years through my passion for learning. I love babies! They are the new generations to come and the possibilities of the future that they bring with them is what keeps me in this line of work. 
                    <br></br><br></br>
                    My name is Jas, and I’d like to introduce myself. I am a queer person of indigenous Central American decent, born and raised in many hoods of LA. I also love being outdoors! It is my medicina and helps me stay grounded. Through nature's medicine and connection with Madre Tierra I continuously learn to tap into my intuition and access ancestral wisdom that is gifted to me. This awakening, combined with my lived experiences throughout LA has compelled me to pay attention and speak up when faced with injustices, especially in the birthing world/hospital industrial complex.
                    
                    <br></br><br></br>
                    I’ve learned through many experiences how daunting birthing can be and so, I am here to offer gentle support to help you navigate this special time for you and your family.    
                    </p>
	            </div>

            </div>
        </div>*/
        <div className="md:container mx-auto" id="Aboutme">
    <div className="grid grid-cols-1 md:grid-cols-2 md:h-full">
      <div className="md:max-h-96 md:h-screen">
        <img className="w-full object-cover object-top" src={selfPortrait} alt="" />
      </div>
      <div className="flex bg-orange-100 p-10">
        <div className="mb-auto mt-auto max-w-lg">
          <h1 className="text-3xl uppercase">Jasmin Castillo</h1>
          <p className="font-semibold mb-5">Doula</p>
          <p className="text-xl">
          My curiosity has led me to many adventures, one being birth. When I became pregnant for the first time I naturally began research of what happens to the preganant body and what the body is capable of during pregnancy, postpartum and beyond. My outlook on birthing and the postpartum period has evolved throughout the years through my passion for learning. I love babies! They are the new generations to come and the possibilities of the future that they bring with them is what keeps me in this line of work. 
                    <br></br><br></br>
                    My name is Jas, and I’d like to introduce myself. I am a queer person of indigenous Central American decent. I was born and raised in many hoods of LA. and first generation in my family to be born here in the states. I  love being outdoors! It is my medicina and helps me stay grounded. Through nature's medicine and connection with Madre Tierra I continuously learn to tap into my intuition and access ancestral wisdom that is gifted to me. This awakening, combined with my lived experiences throughout LA has compelled me to pay attention and speak up when faced with injustice.
                    
                    <br></br><br></br>
                    I’ve learned through many experiences how challenging birth and postpartum can be and so, I am here to offer gentle support to help you navigate this special time for you and your family.
                    </p>
          <button className="bg-black rounded-md py-3 px-7 mt-6 text-white">Contact Me</button>
        </div>
      </div>
    </div>
  </div>
    )
}