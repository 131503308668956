
export default function CTAContact(props) {
  console.log(props.imagesrc)
  let imagesrc = props.imagesrc
return(
<div className="2xl bg-turquoise py-16">
  
  <div className="w-full relative flex items-center justify-center">
    <img src={props.imagesrc} alt="dining" className="w-full h-full absolute z-0 hidden xl:block" />
    <img src={props.imagesrc} alt="dining" className="w-full h-full absolute z-0 hidden sm:block xl:hidden" />
    <img src={props.imagesrc} alt="dining" className="w-full h-full absolute z-0 sm:hidden" />
    <div className=" bg-opacity-10 md:my-16 lg:py-16 py-10 w-full md:mx-24 md:px-12 px-4 flex flex-col items-center justify-center relative z-40">
      <h1 className="text-4xl font-semibold leading-9 text-white text-center">Let's Talk!</h1>
      <h2 className="text-2xl  text-center text-white mt-6">
        Do you have any questions? 
        Get in contact to get support to help you navigate this special time for you and your family.
      </h2>
      <div className="flex-col sm:flex-row flex items-center lg:w-5/12 w-full mt-12 space-y-4 sm:space-y-0">
        {/*<input className="border border-white sm:border-transparent text-base w-full font-medium leading-none text-white p-4 focus:outline-none bg-transparent placeholder-white" placeholder="Email Address" />
        */}
        <button className="mx-auto focus:outline-none focus:ring-offset-2 focus:ring border border-white sm:border-transparent w-full sm:w-auto bg-white py-4 px-6 hover:bg-opacity-75">
            <a href="#Contact">Contact Me</a>
        </button>
      </div>
    </div>
  </div>
</div>
)
}