import { useState } from 'react';
import { send } from 'emailjs-com';



function Contact() {


const [toSend, setToSend] = useState({
  name: '',
  message: '',
  email: '',
  from_name: 'Jas La Doula'
});

const onSubmit = (e) => {
  e.preventDefault();
  send(
    'service_of603f7',
    'template_7mfhsoe',
    toSend,
    '-blJbx9W-a5NPb0n9'
  )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
    })
    .catch((err) => {
      console.log('FAILED...', err);
    });
  alert("Email Sent!");
};


const handleChange = (e) => {
  setToSend({ ...toSend, [e.target.name]: e.target.value });
};


return (
    <div className="relative px-4 items-center relative bg-lturquoise h-screen" id="Contact">
        <div className="w-full text-center">
            <h1 className="text-[36px] font-lturquoise-bg font-bold w-full mx-auto pt-10">Contact</h1>

        </div>
    
        <div className="block p-6 rounded-lg shadow-lg bg-turquoise md:w-1/2 mx-auto">
        <p className="text-white">Service Location(s): Long Beach and Orange County </p>
        <form onSubmit={onSubmit}>
        <div className="form-group mb-6">
        < input type="text" className="form-control block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput7"
        placeholder="Name" 
        name="name"
        value={toSend.name}
        onChange={handleChange}
        />
    </div>
    <div className="form-group mb-6">
      <input type="email" className="form-control block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput8"
        placeholder="Email address"
        name="email"
        value={toSend.email}
        onChange={handleChange}
        />
    </div>
    <div className="form-group mb-6">
      <textarea
      className="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
      id="exampleFormControlTextarea13"
      rows="3"
      placeholder="Message"
      name="message"
      value={toSend.message}
      onChange={handleChange}
    ></textarea>
    </div>

    <button type="submit" className="
      w-full
      px-6
      py-2.5
      bg-blue-600
      text-white
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700 hover:shadow-lg
      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-blue-800 active:shadow-lg
      transition
      duration-150
      bg-lturquoise
      ease-in-out">Send</button>
  </form>
    </div>
</div>
)
}

export default Contact