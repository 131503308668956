import React from "react";
import Card from "./CardP";

// Data for the 3-tier pricing table
export const plans = [
    {
      name: 'Birth & Placenta Medicine - Energy exchange',
      price: '1,888',
      frequency: 'one time',
      features: [
        'Birth doula support (support during labor, on-call for two weeks within your Estimated Due Date)',
        '3 visits included in person or virtually  (2- prenatal visit & 1-postpartum)',
        'Placenta medicine'
      ],
    },
    {
      name: 'Caring Afterbirth - Energy exchange',
      price: '2,222',
      frequency: 'month',
      featured: false,
      features: [
        'Postpartum planning (1 visit prenatally)',
        'Newborn Care class - 1 hour (virtually)',
        'Lactation Counseling & support via text/phone for 40days after birth',
        'Placenta Medicine',  
        'Belly binding', 
        'Closing ceremony at or after your 40 days postpartum', 
        '3 postpartum visits'
      ],
    },
    {
      name: 'Prepared and Supported - Energy exchange',
      price: '3,333',
      frequency: 'month',
      features: [
        'Prenatal education',
        'Birth & Postpartum planning',
        'Birth doula support (support during labor, on-call for two weeks within your EDD)',
        'Placenta Medicine  (encapsulation and tincture)',
        'Newborn Care class - 1 hour virtually',
        'Lactation Counseling & support via text/phone for 40days after birth',
        'Belly binding & abdominal massage',
        '2 prenatal visits',
        '3 postpartum visits',
        'Closing ceremony at or after 40 days postpartum',
        'Resources via email'
      ],
    },
  ];


export default function PricingSection() {
    return (
    <div className="relative bg-green-100 overflow-hidden" id="Packages">
              <div className="w-full text-center">
            <h1 className="text-[36px] font-lturquoise-bg mt-8 mb-6 font-bold w-full mx-auto">Packages</h1>

        </div>
      <div className="grid gap-12 lg:gap-0 lg:grid-cols-3">
      {plans.map(plan => (
        <div
          key={plan.name}
          className={`w-full max-w-lg mx-auto ${
            plan.featured
              ? 'order-first lg:order-none lg:transform lg:scale-90 lg:z-10 min-h-full'
              : 'lg:transform lg:scale-90 min-h-full'
          }`}
        >
          <Card {...plan} />
        </div>
        ))}
        </div>
        <div className="w-full text-center">
            <h2 className="text-[24px] font-lturquoise-bg mt-4 mb-4 font-bold w-full mx-auto">*Custom Packages Available</h2>

        </div>
    </div>
    )
  }